import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import WheelComponent from "react-wheel-of-prizes";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import Imageblod from "../Image/knob.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Wheel from "./Wheel";

function NewVongQuay() {
  // const [segments, setsegments] = useState("");
  // const [segColors, setsegColors] = useState("#000");
  const [valuesegments, setValuesegments] = React.useState("4");
  const [valueColor, setValueColor] = React.useState("2");
  const [valueItemWhell, setvalueItemWhell] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState("one");
  const open = Boolean(anchorEl);
  const segments = ["", "", "", ""];
  const segColors = ["white", "", "", ""];
  const selectItemVong = ["Vòng 1", "Vòng 2", "Vòng 3", "Vòng 4"];
  const onFinished = (winner) => {
    console.log(winner);
  };
  const handleAlignmentsegments = (event, newAlignment) => {
    setValuesegments(newAlignment);
  };
  const handleColor = (event, newAlignment) => {
    setValueColor(newAlignment);
  };
  const handleChange = (event) => {
    setvalueItemWhell(event.target.value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const [rotation, setRotation] = useState(0);

  const handleRotate = () => {
    setRotation(rotation + 45); // Rotate by 45 degrees on each click
  };
  return (
    <div style={{ backgroundColor: "#FBFBFB" }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
            TM Software
          </Typography>

          <IconButton aria-label="delete">
            <SaveOutlinedIcon />
          </IconButton>
          <IconButton aria-label="delete">
            <ShareOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={2}
        style={{
          paddingTop: "30px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid item md={3}>
          <Card sx={{ width: "100%", height: "80vh" }}>
            <CardContent>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  value="one"
                  label="Thiết kế theo TM"
                  style={{ fontSize: "12px" }}
                />
                <Tab
                  value="two"
                  label="Thiết kế của bạn"
                  style={{ fontSize: "12px" }}
                />
              </Tabs>
              {value === "one" && (
                <div style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <div style={{ width: "80px", marginTop: "5px" }}>
                      Số mảnh
                    </div>
                    <ToggleButtonGroup
                      value={valuesegments}
                      exclusive
                      onChange={handleAlignmentsegments}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value="4"
                        size="small"
                        aria-label="left aligned"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valuesegments === "4" ? "teal" : "white",
                          color: valuesegments === "4" ? "white" : "black",
                        }}
                      >
                        4
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valuesegments === "6" ? "teal" : "white",
                          color: valuesegments === "6" ? "white" : "black",
                        }}
                        value="6"
                        aria-label="centered"
                      >
                        6
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="8"
                        aria-label="right aligned"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valuesegments === "8" ? "teal" : "white",
                          color: valuesegments === "8" ? "white" : "black",
                        }}
                      >
                        8
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="12"
                        aria-label="justified"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valuesegments === "12" ? "teal" : "white",
                          color: valuesegments === "12" ? "white" : "black",
                        }}
                      >
                        12
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="16"
                        aria-label="justified"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valuesegments === "16" ? "teal" : "white",
                          color: valuesegments === "16" ? "white" : "black",
                        }}
                      >
                        16
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <div style={{ width: "80px", marginTop: "5px" }}>
                      Số màu
                    </div>
                    <ToggleButtonGroup
                      value={valueColor}
                      exclusive
                      onChange={handleColor}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value="2"
                        size="small"
                        aria-label="left aligned"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valueColor === "2" ? "teal" : "white",
                          color: valueColor === "2" ? "white" : "black",
                        }}
                      >
                        2
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        style={{
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            valueColor === "4" ? "teal" : "white",
                          color: valueColor === "4" ? "white" : "black",
                        }}
                        value="4"
                        aria-label="centered"
                      >
                        4
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <div style={{ width: "80px", marginTop: "5px" }}>
                      Màu nền
                    </div>
                    <Card
                      onClick={handleClick}
                      className="button-text-color"
                      sx={{ backgroundColor: "#3d5afe" }}
                    >
                      <CardContent></CardContent>
                    </Card>
                    <div style={{ width: "8px" }}></div>
                    <Card
                      className="button-text-color"
                      sx={{ backgroundColor: "#00b0ff" }}
                    >
                      <CardContent></CardContent>
                    </Card>
                  </div>
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <div style={{ width: "80px", marginTop: "5px" }}>
                      Màu chữ
                    </div>
                    <Card
                      className="button-text-color"
                      onClick={handleClick}
                      sx={{ backgroundColor: "black" }}
                    >
                      <CardContent></CardContent>
                    </Card>
                    <div style={{ width: "8px" }}></div>
                    <Card className="button-text-color" onClick={handleClick}>
                      <CardContent></CardContent>
                    </Card>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={handleClose}>
                        <Avatar /> Profile
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <Avatar /> My account
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Add another account
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              )}
              {value === "two" && (
                <div style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex", marginBottom: "15px" }}>
                    <div style={{ width: "80px", marginTop: "5px" }}>
                      Số mảnh
                    </div>
                    <ToggleButtonGroup
                      value={valuesegments}
                      exclusive
                      onChange={handleAlignmentsegments}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value="4"
                        size="small"
                        aria-label="left aligned"
                        style={{ width: "35px", height: "35px" }}
                      >
                        4
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        style={{ width: "35px", height: "35px" }}
                        value="6"
                        aria-label="centered"
                      >
                        6
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="8"
                        aria-label="right aligned"
                        style={{ width: "35px", height: "35px" }}
                      >
                        8
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="12"
                        aria-label="justified"
                        style={{ width: "35px", height: "35px" }}
                      >
                        12
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="16"
                        aria-label="justified"
                        style={{ width: "35px", height: "35px" }}
                      >
                        16
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div style={{ display: "flex", marginBottom: "15px" }}></div>
                  <div>
                    <Button variant="contained" style={{ width: "100%" }}>
                      Load ảnh
                    </Button>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={6}
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <WheelComponent
            className="vongquaymayman"
            segments={segments}
            segColors={segColors}
            winningSegment="won 10"
            onFinished={(winner) => onFinished(winner)}
            primaryColor="black"
            contrastColor="white"
            buttonText="Spin"
            isOnlyOnce={false}
            size={290}
            upDuration={100}
            downDuration={1000}
            fontFamily="Arial"
          /> */}
            <Wheel />
          {/* <div className="circle-container">
            <svg width="360" height="370">
              <g transform={`translate(180, 180) rotate(${rotation})`}>
                <circle cx="0" cy="10" r="180" fill="blue" />
              </g>
            </svg>
            <button onClick={handleRotate}>Rotate</button>
          </div> */}
        </Grid>
        <Grid item md={3}>
          <Card sx={{ width: "100%", height: "80vh" }}>
            <CardContent>
              <div>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    size="small"
                    value={valueItemWhell}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {selectItemVong.map((item) => {
                      <MenuItem value={item}>Ten</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText>Without label</FormHelperText>
                </FormControl>
              </div>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default NewVongQuay;
