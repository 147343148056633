import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { NumericFormat } from "react-number-format";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const columns = [
  { field: "stt", headerName: "STT", width: 50 },
  { field: "name", headerName: "Họ và Tên", width: 220 },
  {
    field: "Phone",
    headerName: "Phone",
    width: 150,
  },
];
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

function SendZalo(props) {
  const [age, setAge] = React.useState("");
  const [data, setData] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [reToken, setreToken] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
    console.log(selectionModel);
  };

  const handleButtonClick = () => {
    const selectedRowData = selectedRows.map((id) =>
      data.find((row) => row.id === id)
    );
   
    selectedRowData.map((item) =>
      // console.log(item['Phone']);
      sendZalo(item['Phone'],item['name'])
    );
  };
  useEffect(() => {
    setData(props.data);
    const myCookieValue = getCookie("TMCookie");
    setreToken(myCookieValue);
    console.log(myCookieValue);
  }, []);
  const sendZalo = (phone,name) => {
    const phoneZalo = '84' + phone.substring(1);
    const hd = document.getElementById("outlined-hd").value;
    const money = document.getElementById("outlined-money").value;
    //  const dataBodytoken = {refreshToken: 'OGyTSf9FMd0NK60vp71jQG9dEI-_OnbNP6ug4uTW1L551NLJz1XE9Zy5J6VEAXyaSoicUv4SOZfPA7HqcrqcH2v66Gdn42fm13j_DRKyKKTW1tKMeZL0G5LXN6EbEpTg9q96QRukL4TVVsyydGjkGsmzT0tY62C4KWORJ8eeAGX85cGvz6CDUzSfbrCq9RabRYsCNku5WY4aGiO6mHc49Mnumpse5-quGrhg7k5VsZmaKuPccnlRC4qp406c1ZC4'};
    const dataBodytoken = { 
      refreshToken: reToken,
      user:'XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M',
      phone: phoneZalo,
      money:money,
      kh:name,
      hd:hd
    };
    fetch("https://hoangthongtelecom.com/API/SendZalo/v1/refToken.php", {
      method: "POST",
      body: JSON.stringify(dataBodytoken),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((result) => {
        // Handle the response from the PHP file
        handleClickOpen();
      
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  function getCookie(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return "";
  }

  return (
    <div className="main-dashboard-zalo">
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={7} >
            <DataGrid
              sx={{backgroundColor:'white',margin:'5px'}}
              rows={props.data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              onRowSelectionModelChange={handleSelectionModelChange}
              onSelectionModelChange={handleSelectionModelChange}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <div className="container-send">
              <div>
                <Grid container>
                  <Grid item xs={4}>
                    <p>Loại tin nhắn</p>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        size="small"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Xác nhận thanh toán</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={4}>
                    <p>Hợp đồng</p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      id="outlined-hd"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container>
                  <Grid item xs={4}>
                    <p>Số tiền</p>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      id="outlined-money"
                      variant="outlined"
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  sx={{ backgroundColor: "teal", width: "150px" }}
                  startIcon={<AttachFileIcon />}
                  variant="contained"
                  onClick={handleButtonClick}
                >
                  Send
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
         Thông báo
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         Đã gửi thành công
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default SendZalo;
