import "./App.css";
import { Routes, Route,Navigate  } from "react-router-dom";
import { BrowserRouter,Switch } from "react-router-dom";
import React from "react";
import LoginPage from "./LoginPage";
import MyComponent from './MyComponent';
import TemplateEmailPage from "./Page/TemplateEmailPage";
import EditTemplate from "./Page/EditTemplate";
import PreviewTemplate from "./Page/PreviewTemplate";
import NewVongQuay from "./VongQuay/NewVongQuay";
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />         
          <Route exact path="/" element={<MyComponent/>} />
          <Route exact path="/sms" element={<MyComponent/>} />
          <Route exact path="/Template" element={<TemplateEmailPage />}/>
          <Route exact path="/EditTemplate" element={<EditTemplate />}/>
          <Route exact path="/PreviewTemplate" element={<PreviewTemplate />}/>
          <Route exact path="/VongQuay" element={<NewVongQuay />}/>
          <Route component={<MyComponent/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
