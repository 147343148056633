import React, { useRef ,useState,useEffect} from 'react';
import '../App.css'
import EmailEditor from 'react-email-editor';
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

  function TemplateEmailPage() {
    const [open, setOpen] = React.useState(false);
    const emailEditorRef = useRef(null);
    const [valueHTML, setValueHTML] = useState("");
    const { valueID,setValueID } = useParams();
    const [valueUser, setvalueUser] = useState("");
    const [valueIdTemplate, setvalueIdTemplate] = useState("");
    const [openLoading,setopenLoading] = React.useState(false);

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      const user = urlParams.get('user');
      setvalueIdTemplate(id)
      setvalueUser(user);
      // const user = urlParams.get('user');
  
      console.log('ID:', id);
      // console.log('User:', user);
    }, []);
    const exportHtml = () => {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
      
        console.log('exportHtml', html);
      });
    };
  
    const onReady = () => {

    };
    
    const saveTemplate =() =>{
      setopenLoading(true);
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        setValueHTML(html);
        const dataBody = { 
          idCodecustomer: valueUser ,
          idTemplate:valueIdTemplate,
          contentHTML:html,
          designTemp:JSON.stringify(design)
        };
      fetch(
        "https://hoangthongtelecom.com/API/SendZalo/v1/Email/insertEmailTemplate.php",
        {
          method: "POST",
          body: JSON.stringify(dataBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.text())
        .then((result) => {
          setOpen(true);
          setopenLoading(false);
          //console.log(result);
        })
        .catch((error) => {
          // Handle any error that occurred during the request
          console.error(error);
        });
      });
      
    }
    const handleClose = () => {
      setOpen(false);
    };
    
    return (
      <div className="App">
      <div className='header-template'>
        <button className='button-email-editer' onClick={saveTemplate}>Save</button>
      </div>

      <EmailEditor style={{height:'95vh'}} ref={emailEditorRef} onReady={onReady} />\
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Thông báo</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           "Lưu template thành công"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openLoading}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Vui lòng đợi"}</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
       
      </Dialog>
    </div>
    );
  }
  

  
  export default TemplateEmailPage;