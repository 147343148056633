import "../App.css";
import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";

function SMSZalo(props) {
  const [data, setData] = useState("");
  const dataBodytoken = {
    appid: "3936854956763975893",
  };
  useEffect(() => {
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/NhanTin/getMessageZalo.php",
      {
        method: "POST",
        body: JSON.stringify(dataBodytoken),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.text())
      .then((result) => {
        setData(JSON.parse(result));
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  }, [data]);
  return (
    <>
      <div className="main-sms-zalo">
        {data === "" ? (
          <div></div>
        ) : (
          data.map((item) => (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </div>
              <div>
                <Card sx={{ maxWidth: 375, height: "auto" }}>
                  {item["test"] === '' ? (
                    <div></div>
                  ) : (
                    <CardMedia
                      component="img"
                      height="194"
                      image={item["test"]}
                      alt="Paella dish"
                    />
                  )}

                  <CardContent>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {item["message"]}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default SMSZalo;
