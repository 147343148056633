import React, { useRef, useState, useEffect } from "react";
import "../App.css";
import EmailEditor from "react-email-editor";
import { useParams } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Tooltip from "@mui/material/Tooltip";
import Iframe from "react-iframe";

function PreviewTemplate() {
  const emailEditorRef = useRef(null);
  const [valueHTML, setValueHTML] = useState("");
  const { valueID, setValueID } = useParams();
  const [valueUser, setvalueUser] = useState("");
  const [valueIdTemplate, setvalueIdTemplate] = useState("");
  const [alignment, setAlignment] = React.useState("Desktop");

  const iframeStyle = {
    width: "90%",
    height: "89vh", // Set the desired height as a percentage of the viewport height
    border: "0",
  };
  const iframeStyleTablet = {
    width: "768px",
    height: "89vh", // Set the desired height as a percentage of the viewport height
    border: "0",
  };
  const iframeStyleMobile = {
    width: "364px",
    height: "89vh", // Set the desired height as a percentage of the viewport height
    border: "0",
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const user = urlParams.get("user");
    setvalueIdTemplate(id);
    setvalueUser(user);
    const url =
      "https://hoangthongtelecom.com/API/SendZalo/v1/Email/FileHtml/" +
      id +
      ".html";
    setValueHTML(url);
  }, []);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              TM Software
            </Typography>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
              >
                <ToggleButton value="Desktop" aria-label="left aligned">
                  <Tooltip title="Desktop">
                    <IconButton>
                      <PersonalVideoIcon />
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="Tablet" aria-label="centered">
                  <Tooltip title="Tablet">
                    <IconButton>
                      <TabletMacIcon />
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="Mobile" aria-label="right aligned">
                  <Tooltip title="Mobile">
                    <IconButton>
                      <PhoneIphoneIcon />
                    </IconButton>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{textAlign:'center'}}>
        {alignment === "Desktop" && (
          <Iframe
            url={valueHTML}
            styles={iframeStyle}
            frameBorder="0"
            allowFullScreen
          />
        )}
        {alignment === "Tablet" && (
          <Iframe
            url={valueHTML}
            styles={iframeStyleTablet}
            frameBorder="0"
            allowFullScreen
          />
        )}
        {alignment === "Mobile" && (
          <Iframe
            url={valueHTML}
            styles={iframeStyleMobile}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </div>
    </div>
  );
}

export default PreviewTemplate;
