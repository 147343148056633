import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import MailIcon from "@mui/icons-material/Mail";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardEmail from "./Page/DashboardEmail";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendZalo from "./Page/SendZalo";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "react-responsive";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import SmsIcon from "@mui/icons-material/Sms";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";
import SMSZalo from "./NhanTin/SMSZalo";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Dashboard from "./Page/Dashboard";
import ViewCozyIcon from "@mui/icons-material/ViewCozy";
import TemplatePage from "./Page/TemplatePage";
import * as XLSX from "xlsx";
import Link from "@mui/material/Link";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import BrushIcon from "@mui/icons-material/Brush";
import DesignPage from "./VongQuay/DesignPage";
import EntryPage from "./Page/EntryPage";

const drawerWidth = 180;
const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
function MyComponent(props) {
  const { window } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openLoadExcel, setopenLoadExcel] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [valueCodeUser, setValueCodeUser] = React.useState("");
  const [openThongbaoquyen,setopenThongbaoquyen] = React.useState(false);

  const columns = [
    { field: "stt", headerName: "STT", width: 40 },
    { field: "name", headerName: "Họ và Tên", width: 210 },
    { field: "sex", headerName: "Giới tính", width: 65 },
    {
      field: "Phone",
      headerName: "Phone",
      width: 110,
    },
    { field: "Email", headerName: "Email", width: 210 },
    { field: "birthday", headerName: "Sinh nhật", width: 110 },
    { field: "address", headerName: "Địa chỉ", width: 250 },
    {
      field: "edit",
      headerName: "Edit",
      width: 40,
      renderCell: (params) => (
        <IconButton aria-label="edit" onClick={() => handleEdit(params.row.id)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() => handleDelete(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const [textValueidSelect, setTextValueidSelect] = useState("");
  const [textValue, setTextValue] = useState("");
  const [textValuePhone, setTextValuePhone] = useState("");
  const [textValueEmail, setTextValueEmail] = useState("");
  const [textValueBirthday, setTextValueBirthday] = useState("");
  const [sex, setSex] = React.useState("");
  const [sexEdit, setSexEdit] = React.useState("");
  const [textValueAddress, setTextValueAddress] = useState("");
  const [valueDataExcel, setvalueDataExcel] = useState("");

  const handleEdit = (idSelect) => {
    handleOpenEdit(true);
    setTextValueidSelect(idSelect);
    const item = data.find((item) => item.id === idSelect);
    setTextValue(item["name"]);
    setTextValuePhone(item["Phone"]);
    setTextValueEmail(item["Email"]);
    setTextValueBirthday(item["birthday"]);
    setSexEdit(item["sex"]);
    setTextValueAddress(item["address"]);
  };

  const handleInputChangeName = (event) => {
    setTextValue(event.target.value);
  };
  const handleInputChangeEmail = (event) => {
    setTextValueEmail(event.target.value);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleChangeEdit = (event) => {
    setSexEdit(event.target.value);
  };

  const updateCustomer = () => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const dataBodyCustomer = {
      id: textValueidSelect,
      phone: textValuePhone,
      name: textValue,
      address: textValueAddress,
      sex: sexEdit,
      Email: textValueEmail,
      birthday: textValueBirthday,
      idCustomer: myCookieValue,
    };
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/updateCustomerOfUser.php",
      {
        method: "POST",
        body: JSON.stringify(dataBodyCustomer),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.text())
      .then((result) => {
        // Handle the response from the PHP file
        handleCloseEdit();
        //setData(JSON.parse(result));
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  const handleDelete = (idSelect) => {
    const dataBodyIdUser = {
      id: idSelect,
    };
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/deleteCustomerOfUser.php",
      {
        method: "POST",
        body: JSON.stringify(dataBodyIdUser),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.text())
      .then((result) => {})
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  const [selectedItem, setSelectedItem] = useState("Item 5");
  const [data, setData] = useState("");
  const [dataNameUser, setDataNameUser] = useState("");
  const [valueKeyAPI, setvalueKeyAPI] = useState("");

  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    setValueCodeUser(myCookieValue);
    if (myCookieValue === "") {
      let path = "/login";
      navigate(path);
    } else {
      const dataBody = { idCustomer: myCookieValue };
      fetch(
        "https://hoangthongtelecom.com/API/SendZalo/v1/getCustomerOfUser.php",
        {
          method: "POST",
          body: JSON.stringify(dataBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.text())
        .then((result) => {
          // Handle the response from the PHP file
          setData(JSON.parse(result));
        })
        .catch((error) => {
          // Handle any error that occurred during the request
          console.error(error);
        });
      const myCookieNameValue = getCookieCode("TMUserNameCookie");
      setDataNameUser(myCookieNameValue);
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [data]);

  function getCookieCode(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return "";
  }
  const handleItemClick = (item) => {
    if(valueCodeUser ==="XsJKDd8NQ=}2eAcSmxc-ngwR/Hf-Hh9M"){
      setSelectedItem(item);
    }else{
      if(item==="Item 3" || item==="Item 7"){
        handleOpenThongbaoQuyen(true);
      }else{
        setSelectedItem(item);
      }
    }
    
  };
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChange = (event) => {
    setSex(event.target.value);
  };

  const saveCustomer = () => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const textName = document.getElementById("outlined-name").value;
    const textPhone = document.getElementById("outlined-phone").value;

    const textAddress = document.getElementById("outlined-address").value;
    const textEmail = document.getElementById("outlined-email").value;
    const textBirthday = document.getElementById("outlined-birthday").value;
    const dataBodyCustomer = {
      phone: textPhone,
      name: textName,
      address: textAddress,
      sex: sex,
      Email: textEmail,
      birthday: textBirthday,
      idCustomer: myCookieValue,
    };
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/insertCustomerOfUser.php",
      {
        method: "POST",
        body: JSON.stringify(dataBodyCustomer),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.text())
      .then((result) => {
        // Handle the response from the PHP file
        handleClickCloseAdd();
        setData(JSON.parse(result));
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
 const handleCloseThongbaoQuyen=()=>{
    setopenThongbaoquyen(false);
  }

  const handleOpenThongbaoQuyen=()=>{
    setopenThongbaoquyen(true);
  }
  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };
  let navigate = useNavigate();
  const logOut = () => {
    document.cookie = "TMUserLogin=0";
    let path = "/login";
    navigate(path);
  };

  const clickAddcount = () => {};

  const openFormLoadExcell = () => {
    setopenLoadExcel(true);
  };
  const closeImportExcell = () => {
    setopenLoadExcel(false);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setvalueDataExcel(excelData);
      console.log(excelData);
    };

    reader.readAsBinaryString(file);
  };

  const saveExcellToDatabase = () => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    valueDataExcel.slice(1).map((item) => {
      const name = item[0];
      const sex = item[1];
      const phone = item[2];
      const mail = item[3];
      const sinhnhat = item[4];
      const address = item[5];
      const dataBodyCustomer = {
        phone: phone,
        name: name,
        address: address,
        sex: sex,
        Email: mail,
        birthday: sinhnhat,
        idCustomer: myCookieValue,
      };
      fetch(
        "https://hoangthongtelecom.com/API/SendZalo/v1/insertCustomerOfUser.php",
        {
          method: "POST",
          body: JSON.stringify(dataBodyCustomer),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.text())
        .then((result) => {
          // Handle the response from the PHP file
          handleClickCloseAdd();
          setData(JSON.parse(result));
        })
        .catch((error) => {
          // Handle any error that occurred during the request
          console.error(error);
        });
    });
  };

  const dowloadFile = () => {
    const url =
      "https://hoangthongtelecom.com/API/SendZalo/v1/Email/fileExcell/sample.xlsx";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "sample.xlsx"); // Specify the desired filename for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        TM Software
      </Typography>
      <Divider />
      {/* <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <List>
        <ListItem
          button
          onClick={() => handleItemClick("Item 5")}
          disablePadding
          sx={{
            backgroundColor: selectedItem === "Item 5" ? "grey" : "white",
            color: selectedItem === "Item 5" ? "white" : "black",
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem
          button
          onClick={() => handleItemClick("Item 1")}
          disablePadding
          sx={{
            backgroundColor: selectedItem === "Item 1" ? "grey" : "white",
            color: selectedItem === "Item 1" ? "white" : "black",
          }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PeopleAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Khách hàng" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            backgroundColor: selectedItem === "Item 2" ? "grey" : "white",
            color: selectedItem === "Item 2" ? "white" : "black",
          }}
          onClick={() => handleItemClick("Item 2")}
        >
          <ListItemButton>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Email" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            backgroundColor: selectedItem === "Item 3" ? "grey" : "white",
            color: selectedItem === "Item 3" ? "white" : "black",
          }}
          onClick={() => handleItemClick("Item 3")}
        >
          <ListItemButton>
            <ListItemIcon>
              <AutoAwesomeMotionIcon />
            </ListItemIcon>
            <ListItemText primary="Zalo" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      {isMobile && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  TM Software
                </Typography>
                <Avatar>H</Avatar>
              </Toolbar>
            </AppBar>
            <Box component="nav">
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
          </Box>
        </>
      )}
      {isDesktop && (
        <>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <AppBar
              position="fixed"
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
              <Toolbar>
                <Typography
                  variant="h4"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1 }}
                >
                  TM Software
                </Typography>
                {/* 
                <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                {dataNameUser}
                <div className="space"></div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Box> */}
                {dataNameUser}
                <div className="space"></div>
                <Box sx={{ flexGrow: 0, display: "flex" }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={dataNameUser}
                        src="/static/images/avatar/2.jpg"
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {/* {settings.map((setting) => (
                      <MenuItem key={setting} onClick={logOut}>
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))} */}
                    <MenuItem onClick={clickAddcount}>
                      <Typography textAlign="center">Account</Typography>
                    </MenuItem>
                    <MenuItem onClick={logOut}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Toolbar>
            </AppBar>

            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
            >
              <Toolbar />
              <Box sx={{ overflow: "auto" }}>
                <nav aria-label="main mailbox folders">
                  <div style={{ margin: 8, color: "blue", fontWeight: "bold" }}>
                    Email
                  </div>
                  <List>
                    <ListItem
                      button
                      onClick={() => handleItemClick("Item 5")}
                      disablePadding
                      sx={{
                        backgroundColor:
                          selectedItem === "Item 5" ? "grey" : "white",
                        color: selectedItem === "Item 5" ? "white" : "black",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                      </ListItemButton>
                    </ListItem>

                    <ListItem
                      disablePadding
                      sx={{
                        backgroundColor:
                          selectedItem === "Item 2" ? "grey" : "white",
                        color: selectedItem === "Item 2" ? "white" : "black",
                      }}
                      onClick={() => handleItemClick("Item 2")}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Gửi email" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{
                        backgroundColor:
                          selectedItem === "Item 6" ? "grey" : "white",
                        color: selectedItem === "Item 6" ? "white" : "black",
                      }}
                      onClick={() => handleItemClick("Item 6")}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <ViewCozyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Template" />
                      </ListItemButton>
                    </ListItem>
                  </List>

                  <Divider />
                  <div style={{ margin: 8, color: "blue", fontWeight: "bold" }}>
                    Zalo
                  </div>
                  <List>
                    <ListItem
                      disablePadding
                      sx={{
                        backgroundColor:
                          selectedItem === "Item 3" ? "grey" : "white",
                        color: selectedItem === "Item 3" ? "white" : "black",
                      }}
                      onClick={() => handleItemClick("Item 3")}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <AutoAwesomeMotionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tin giao dịch" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <Divider />
                  <div style={{ margin: 8, color: "blue", fontWeight: "bold" }}>
                    Vòng quay
                  </div>
                  <List>
                    <ListItem
                      button
                      onClick={() => handleItemClick("Item 7")}
                      disablePadding
                      sx={{
                        backgroundColor:
                          selectedItem === "Item 7" ? "grey" : "white",
                        color: selectedItem === "Item 7" ? "white" : "black",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <BrushIcon />
                        </ListItemIcon>
                        <ListItemText primary="Thiết kế" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      button
                      onClick={() => handleItemClick("Item 1")}
                      disablePadding
                      sx={{
                        backgroundColor:
                          selectedItem === "Item 1" ? "grey" : "white",
                        color: selectedItem === "Item 1" ? "white" : "black",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <PeopleAltOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Khách hàng" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </nav>
              </Box>
            </Drawer>
          </Box>
        </>
      )}
      <Box
        component="main"
        sx={{ width: "100%", paddingLeft: isMobile ? "0px" : "185px" }}
      >
        {isMobile ? <div></div> : <Toolbar />}
        {selectedItem === "Item 1" && (
          <div>
            <Stack
              spacing={1}
              direction="row"
              style={{
                height: 45,
                width: "100%",
                marginTop: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <h4>Danh sách khách hàng</h4>
              </div>
              <div>
                {isMobile ? (
                  <div style={{ marginRight: 12 }}>
                    <Button
                      style={{ height: "40px" }}
                      onClick={handleClickOpenAdd}
                      startIcon={<AddIcon />}
                      variant="contained"
                    >
                      Thêm
                    </Button>
                  </div>
                ) : (
                  <>
                    {/* <Button
                      onClick={handleClickOpen}
                      startIcon={<AttachFileIcon />}
                      variant="contained"
                      sx={{ marginRight: "8px" }}
                    >
                      Excell
                    </Button> */}
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={handleClickOpenAdd}
                      startIcon={<AddIcon />}
                      variant="contained"
                    >
                      Thêm
                    </Button>
                    <Button
                      style={{ height: "40px", marginRight: "10px" }}
                      onClick={openFormLoadExcell}
                      startIcon={<AttachFileOutlinedIcon />}
                      variant="contained"
                    >
                      Import
                    </Button>
                  </>
                )}
              </div>
            </Stack>
            {/* <TableData /> */}
            <DataGrid
              rows={data}
              sx={{ height: "80vh", marginRight: "5px", marginLeft: "5px" }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              // onRowSelectionModelChange={handleSelectionModelChange}
              // onSelectionModelChange={handleSelectionModelChange}
            />
          </div>
        )}
        {selectedItem === "Item 2" && (
          <DashboardEmail data={data} setData={setData} />
        )}
        {selectedItem === "Item 3" &&
            <SendZalo data={data} setData={setData} />
         }
        {selectedItem === "Item 4" && <SMSZalo data={data} setData={setData} />}
        {selectedItem === "Item 5" && (
          <Dashboard data={valueKeyAPI} setData={setvalueKeyAPI} />
        )}
        {selectedItem === "Item 6" && (
          <TemplatePage data={data} setData={setData} />
        )}
        {selectedItem === "Item 7" && 
          <DesignPage />
       }
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upload file excel</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAdd}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thêm khách hàng"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>Họ và Tên</div>
            <TextField
              id="outlined-name"
              size="small"
              fullWidth
              variant="outlined"
            />
            <div style={{ height: 8 }}></div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 8 }}>
                <div>Giới tính</div>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sex}
                    size="small"
                    sx={{ width: "100px" }}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Nam"}>Nam</MenuItem>
                    <MenuItem value={"Nữ"}>Nữ</MenuItem>
                    <MenuItem value={"Khác"}>Khác</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <div>Sinh nhật</div>
                <TextField
                  id="outlined-birthday"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>

            <div style={{ height: 8 }}></div>
            <div>Số điện thoại</div>
            <TextField
              id="outlined-phone"
              size="small"
              fullWidth
              variant="outlined"
            />
            <div style={{ height: 8 }}></div>
            <div>Email</div>
            <TextField
              id="outlined-email"
              size="small"
              fullWidth
              variant="outlined"
            />
            <div style={{ height: 8 }}></div>
            <div>Địa chỉ</div>
            <TextField
              id="outlined-address"
              size="small"
              fullWidth
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={saveCustomer}>Save</Button>
          <Button onClick={handleClickCloseAdd} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thêm khách hàng"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>Họ và Tên</div>
            <TextField
              id="outlined-name"
              size="small"
              sx={{ width: 320 }}
              variant="outlined"
              onChange={handleInputChangeName}
              value={textValue}
            />
            <div style={{ height: 8 }}></div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 8 }}>
                <div>Giới tính</div>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sexEdit}
                    size="small"
                    sx={{ width: "100px" }}
                    onChange={handleChangeEdit}
                  >
                    <MenuItem value={"Nam"}>Nam</MenuItem>
                    <MenuItem value={"Nữ"}>Nữ</MenuItem>
                    <MenuItem value={"Khác"}>Khác</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <div>Sinh nhật</div>
                <TextField
                  id="outlined-birthday"
                  size="small"
                  variant="outlined"
                  sx={{ width: "210px" }}
                  value={textValueBirthday}
                />
              </div>
            </div>

            <div style={{ height: 8 }}></div>
            <div>Số điện thoại</div>
            <TextField
              id="outlined-phone"
              size="small"
              sx={{ width: 320 }}
              variant="outlined"
              value={textValuePhone}
            />
            <div style={{ height: 8 }}></div>
            <div>Email</div>
            <TextField
              id="outlined-email"
              size="small"
              sx={{ width: 320 }}
              variant="outlined"
              onChange={handleInputChangeEmail}
              value={textValueEmail}
            />
            <div style={{ height: 8 }}></div>
            <div>Địa chỉ</div>
            <TextField
              id="outlined-address"
              size="small"
              value={textValueAddress}
              sx={{ width: 320 }}
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateCustomer}>Save</Button>
          <Button onClick={handleCloseEdit} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLoadExcel}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Load dữ liệu từ file excell"}
        </DialogTitle>
        <DialogContent>
          <div>- Cho phép tải tập tin có dung lượng tối đa 512M.</div>
          <div>- Chấp nhận các định dạng file xlsx,xls,csv</div>
          <div>- Thứ tự cột Name,Giới tính,phone,email....</div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "8px" }}>- Tải file mẫu tại đây </div>
            <Link href="#" onClick={dowloadFile}>
              sample.xlsx
            </Link>
          </div>
          <div style={{ height: "15px" }}></div>
          <input type="file" onChange={handleFileSelect} />
        </DialogContent>
        <DialogActions>
          <Button onClick={saveExcellToDatabase} autoFocus>
            Save
          </Button>
          <Button onClick={closeImportExcell} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openThongbaoquyen}
        onClose={handleCloseThongbaoQuyen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Thông báo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tài khoản bạn chưa được sử dụng tính năng này. Vui lòng liên hệ TM Software để được hỗ trợ.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseThongbaoQuyen}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
MyComponent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
export default MyComponent;
