import React, { useState, useEffect } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
import { format } from "date-fns";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
function Dashboard(props) {
  const [valueReport, setvalueReport] = useState("");
  const [valueReportContact, setvalueReportContact] = useState("");
  const [valueKeyAPI, setvalueKeyAPI] = useState("");
  //const [loading, setLoading] = useState(true);
  useEffect(() => {
    getApiEmail();
  }, [valueKeyAPI]);

  const getApiEmail = async () => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const dataBody = { idCustomer: myCookieValue };
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/Email/getEmailAPI.php",
      {
        method: "POST",
        body: JSON.stringify(dataBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const data = result;
        const key = data[0]["keyAPI"];
        getThongKe(key);
        getAllContact(key);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const getThongKe = (code) => {
    const today = new Date();
    const formattedDate = format(today, 'yyyy-MM-dd');
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "api-key": code,
      },
    };
     const apiUrl = `https://api.brevo.com/v3/smtp/statistics/aggregatedReport`;
    fetch(apiUrl, options)
      .then((response) => response.json())
      .then((response) => {
        setvalueReport(response);
        console.log(response);
      })
      .catch((err) => console.error(err));
  };

  const getAllContact = (code) => {
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "api-key": code,
      },
    };

    fetch("https://api.brevo.com/v3/contacts", options)
      .then((response) => response.json())
      .then((response) => {
        const data = response["count"];
        setvalueReportContact(data);
      })
      .catch((err) => console.error(err));
  };

  function getCookieCode(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return "";
  }
  return (
    <div>
      {/* <div style={{ marginBottom: "10px" }}>Dashboard</div> */}
      {valueReportContact===''?<div style={{marginTop:200,textAlign:'center', width:'100%'}}>
          <CircularProgress />
      </div>:
      <div style={{ paddingLeft: "4%", paddingRight: "4%", marginTop: "40px" }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <div style={{ marginBottom: "20px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>Hạn mức gửi</div>
                <div style={{ marginRight: "10px" }}>
                  {valueReport["requests"]}/5000
                </div>
                <div>{(valueReport["requests"] * 100) / 200}%</div>
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={(valueReport["requests"] * 100) / 200}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginBottom: "20px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>Liên hệ</div>
                <div style={{ marginRight: "10px" }}>{valueReportContact}</div>
                <div></div>
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={valueReportContact}
              />
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "50px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "blue",
          }}
        >
          Thống kê
        </div>
        <div style={{ marginTop: "20px" }}>
          <Grid container spacing={2}>
            <Grid item sm={2} md={3}>
              <div style={{ marginBottom: "10px" }}>Đã gửi</div>
              <div style={{ marginBottom: "10px" }}>Gửi thành công</div>
              <div style={{ marginBottom: "10px" }}>Người mở</div>
              <div style={{ marginBottom: "10px" }}>Người click</div>
              <div style={{ marginBottom: "10px" }}>Trả lại</div>
              <div style={{ marginBottom: "10px" }}>Thư rác</div>
              <div>Hủy đăng ký</div>
            </Grid>
            <Grid item sm={2} md={2}>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["requests"]}
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["delivered"]}
              </div>
              <div style={{ marginBottom: "10px" }}>{valueReport["opens"]}</div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["clicks"]}
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["hardBounces"] + valueReport["softBounces"]}
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["spamReports"]}
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["unsubscribed"]}
              </div>
            </Grid>
            <Grid item sm={2} md={1}>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["requests"]===0?"0":(valueReport["requests"] * 100) /
                  (valueReport["requests"] +
                    valueReport["hardBounces"] +
                    valueReport["spamReports"])}
                %
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["delivered"] ===0?"0": (Math.floor(
                  (valueReport["delivered"] * 100) / valueReport["requests"]
                ))}
                %
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["opens"] ===0?"0": (Math.floor(
                  (valueReport["opens"] * 100) / valueReport["requests"]
                ))}
                %
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["clicks"] ===0?"0":(Math.floor(
                  (valueReport["clicks"] * 100) / valueReport["requests"]
                ))}
                %
              </div>  
              <div style={{ marginBottom: "10px" }}>
                {valueReport["hardBounces"] ===0 ?"0": (Math.floor(
                  ((valueReport["hardBounces"] + valueReport["softBounces"]) *
                    100) /
                    valueReport["requests"]
                ))}
                %
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["spamReports"]===0?"0":(Math.floor(
                  (valueReport["spamReports"] * 100) / valueReport["requests"]
                ))}
                %
              </div>
              <div style={{ marginBottom: "10px" }}>
                {valueReport["unsubscribed"]===0?"0":(Math.floor(
                  (valueReport["unsubscribed"] * 100) / valueReport["requests"]
                ))}
                %
              </div>
            </Grid>
            <Grid item sm={3} md={4}>
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "10px" }}
                variant="determinate"
                value={
                  valueReport["requests"] ===0?0:
                  (valueReport["requests"] * 100) /
                  (valueReport["requests"] +
                    valueReport["hardBounces"] +
                    valueReport["spamReports"])
                }
              />
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "15px" }}
                variant="determinate"
                value={
                  valueReport["delivered"] === 0 ?0 :
                  (valueReport["delivered"] * 100) / valueReport["requests"]
                }
              />
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "20px" }}
                variant="determinate"
                value={ valueReport["opens"] === 0 ? 0 :
                  Math.min(
                  (valueReport["opens"] * 100) / valueReport["requests"],
                  100
                )}
              />
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "25px" }}
                variant="determinate"
                value={
                  valueReport["clicks"] === 0 ? 0 :
                  Math.min(
                  (valueReport["clicks"] * 100) / valueReport["requests"],
                  100
                )}
              />
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "25px" }}
                variant="determinate"
                value={
                  valueReport["hardBounces"] === 0 ? 0 :
                  ((valueReport["hardBounces"] + valueReport["softBounces"]) *
                    100) /
                  valueReport["requests"]
                }
              />
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "25px" }}
                variant="determinate"
                value={
                  valueReport["spamReports"] === 0 ? 0 :
                  (valueReport["spamReports"] * 100) / valueReport["requests"]
                }
              />
              <BorderLinearProgress
                style={{ marginBottom: "20px", marginTop: "25px" }}
                variant="determinate"
                value={
                  valueReport["unsubscribed"] === 0 ? 0 :
                  (valueReport["unsubscribed"] * 100) / valueReport["requests"]
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>}
    </div>
  );
}
export default Dashboard;
