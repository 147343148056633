import React, { useState, useEffect } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
function DesignPage() {
    const [valueTemplate, setvalueTemplate] = useState("");
    useEffect(() => {
      getEmailTemplate();
      }, [valueTemplate]);

      const getEmailTemplate = () => {
        const myCookieValue = getCookieCode("TMUserCodeCookie");
        const dataBody = { idCustomer: myCookieValue };
        fetch(
          "https://hoangthongtelecom.com/API/SendZalo/v1/Email/getEmailTemplate.php",
          {
            method: "POST",
            body: JSON.stringify(dataBody),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            const data = result;
            // Handle the response from the PHP file
            setvalueTemplate(data);
          })
          .catch((error) => {
            // Handle any error that occurred during the request
            console.error(error);
          });
      };
      const editTemplate = (e) => {
        const myCookieValue = getCookieCode("TMUserCodeCookie");
        const idTempalate = e.target.id;
        const newTab = window.open(
          "./EditTemplate?user=" + myCookieValue + "&id=" + idTempalate,
          "_blank"
        );
      };
      function getCookieCode(name) {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(name + "=")) {
            return cookie.substring(name.length + 1);
          }
        }
        return "";
      }
      const handleButtonTemplateClick = (e) => {
        // Update the color of the clicked card
        const idTempalate = e.target.id;
        const foundItem = valueTemplate.find(
          (item) => item["idTemplate"] === idTempalate
        );
      };
  const createNewTemplate =()=>{

  }
  const openNewTab = (event) => {
    event.preventDefault();
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const randomString = generateRandomString(6);
    const newTab = window.open(
      "./VongQuay?user=" + myCookieValue + "&id=" + randomString,
      "_blank"
    ); // Thay đổi đường dẫn file b.js tùy theo yêu cầu
  };
  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const openPreviewTemplate =(e)=>{
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const idTempalate = e.target.id;
    const newTab = window.open(
      "./PreviewTemplate?user=" + myCookieValue + "&id=" + idTempalate,
      "_blank"
    );
  }
  return (
    <div>
      <div style={{display:'flex',marginBottom:'10px',marginTop:'10px'}}>
        <div style={{flexGrow:1,fontWeight:'bold'}}>
          Danh sách vòng quay
        </div>
        <div style={{marginRight:'15px'}}>
       <Button
                  to="/Template"
                  size="small"
                  variant="text"
                  onClick={openNewTab}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                >
                 New
                </Button>
      </div>
      </div>
       
      <Grid container spacing={2}>
            {valueTemplate === "" ? (
              <div></div>
            ) : (
              valueTemplate.map((item, index) => (
                <Grid item md={2}>
                  <div className="div-card-template" key={index}>
                    <Card
                      key={item["idTemplate"]}
                      sx={{
                        maxWidth: 355,
                        overflow: "hidden",
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "0px 8px 6px -6px #999",
                          backgroundColor: "grey",
                        },
                        "&hove..hover-button": {
                          display: "block",
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ height: 280, margin: "5px", objectFit: "cover" }}
                        image={item["imageTemplate"]}
                        title="green iguana"
                        alt="Description"
                      />
                    </Card>
                    <button
                      className="hidden-button"
                      onClick={openPreviewTemplate}
                      id={item["idTemplate"]}
                    >
                      Preview
                    </button>
                    <button
                      className="hidden-button-edit"
                      onClick={editTemplate}
                      id={item["idTemplate"]}
                    >
                      Edit
                    </button>
                    <button
                      className="hidden-button-delete"
                      onClick={editTemplate}
                      id={item["idTemplate"]}
                    >
                      Delete
                    </button>
                  </div>
                </Grid>
              ))
            )}
          </Grid>
    </div>
  );
}
export default DesignPage;
