import React,{useEffect} from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
function LoginPage() {
  const [showPassword, setShowPassword] = React.useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    const myCheckLogin = getCookieCode("TMUserLogin");
    if(myCheckLogin ==='1'){
      let path = "/sms";
      navigate(path);
    }
  });
  const routeChange = () => {
    const textName = document.getElementById("outlined-user").value;
    const textPass = document.getElementById("outlined-adornment-password").value;
    if (textName !== "") {
      const dataBody = {
        user: textName,
        pass: textPass,
      };
      fetch("https://hoangthongtelecom.com/API/SendZalo/v1/UserLogin.php", {
        method: "POST",
        body: JSON.stringify(dataBody),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.text())
        .then((result) => {
          // Handle the response from the PHP file
          console.log(JSON.parse(result));
          const message = JSON.parse(result)["message"];
          const userName = JSON.parse(result)['data']["customerName"];
          const userCode = JSON.parse(result)['data']["customerCode"];
          if (message === "OK") {
            document.cookie = "TMUserLogin=1";
            document.cookie = "TMUserNameCookie="+userName;
            document.cookie = "TMUserCodeCookie="+userCode;
            let path = "/sms";
            navigate(path);
          }
        })
        .catch((error) => {
          // Handle any error that occurred during the request
          console.error(error);
        });
    }
  };
  function getCookieCode(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return "";
  }
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="App">
      <div className="App-header">
        <Card sx={{ minWidth: 360 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 22 }}
              color="text.secondary"
              gutterBottom
            >
              ĐĂNG NHẬP
            </Typography>
            <div style={{ textAlign: "start" }}>Tên đăng nhập</div>
            <div style={{ width: "100%", marginBottom: "20px", marginTop: 5 }}>
              <TextField
                size="small"
                fullWidth
                id="outlined-user"
                placeholder="Tên đăng nhập"
                variant="outlined"
              />
            </div>
            <div style={{ textAlign: "start" }}>Mật khâu</div>
            <div style={{ width: "100%", marginTop: 5 }}>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <OutlinedInput
                  size="small"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div style={{ textAlign: "start" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Ghi nhớ đăng nhập"
              />
            </div>
          </CardContent>
          <CardActions>
            <Button
              onClick={routeChange}
              style={{ width: "100%", marginLeft: 8, marginRight: 8 }}
              variant="contained"
            >
              Đăng nhập
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  );
}

export default LoginPage;
