/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import "dayjs/locale/en-gb";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";

const columns = [
  { field: "stt", headerName: "STT", width: 30 },
  { field: "name", headerName: "Họ và Tên", width: 220 },
  {
    field: "Email",
    headerName: "Email",
    width: 220,
  },
];
const inputStyle = {
  width: 250, // Set the width you desire
  fontSize: 16,
  height: 30, // Set the font size you desire
  /* Add any other styles you want */
};
function DashboardEmail(props) {
  const [open, setOpen] = React.useState(false);
  const [openTemplate, setOpenTemplate] = React.useState(false);
  // const [valueHTML, setValueHTML] = useState("");
  const [valueTemplate, setvalueTemplate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [cardselect, setcardselect] = useState("");
  const [valueUser, setvalueUser] = useState("");
  const [selectedValue, setSelectedValue] = React.useState("Now");
  const [openSender, setOpenSender] = React.useState(false);
  const [valueThongbao, setvalueThongbao] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs(new Date()));
  const [openActiveEmail, setopenActiveEmail] = React.useState(false);
  const [valueIDSender, setvalueIDSender] = useState("");
  const [valueKeyAPI, setvalueKeyAPI] = useState("");
  const [valueEmail, setvalueEmail] = useState("");
  const [openLoading, setopenLoading] = React.useState(false);
  // const outputDateFormat = "dd/MM/yyyy HH:mm";

  const handleChangeSendEmail = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    getEmailTemplate();
    getApiEmail();
  }, [valueTemplate]);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const getApiEmail = () => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const dataBody = { idCustomer: myCookieValue };
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/Email/getEmailAPI.php",
      {
        method: "POST",
        body: JSON.stringify(dataBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const data = result;
        const key = data[0]["keyAPI"];
        const email = data[0]["email"];
        setvalueKeyAPI(key);
        setvalueEmail(email);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const openDialogTemplate = () => {
    setOpenTemplate(true);
  };

  const closeDialogTemplate = () => {
    setOpenTemplate(false);
  };
  const handleCloseSender = () => {
    setOpenSender(false);
  };
  const handleOpenSender = () => {
    setOpenSender(true);
  };
  const handleButtonTemplateClick = (e) => {
    // Update the color of the clicked card
    const idTempalate = e.target.id;
    const foundItem = valueTemplate.find(
      (item) => item["idTemplate"] === idTempalate
    );
    setcardselect(foundItem);
    setOpenTemplate(false);
  };
  const openNewTab = (event) => {
    event.preventDefault();
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    setvalueUser(myCookieValue);
    const randomString = generateRandomString(6);
    const newTab = window.open(
      "./Template?user=" + myCookieValue + "&id=" + randomString,
      "_blank"
    ); // Thay đổi đường dẫn file b.js tùy theo yêu cầu
  };

  const editTemplate = (e) => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const idTempalate = e.target.id;
    const newTab = window.open(
      "./EditTemplate?user=" + myCookieValue + "&id=" + idTempalate,
      "_blank"
    );
  };
  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  function getCookieCode(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
    return "";
  }
  const sendEmailMarketing = (item) => {
    
    const emailTo = item["Email"];
    const nameTo = item["name"];
    let emailData;
    const apiKey = valueKeyAPI;
    const myCookieValueName = getCookieCode("TMUserNameCookie");
    const sub = document.getElementById("outlined-subject").value;
    const email = document.getElementById("outlined-email").value;
    // Email data
    if (selectedValue === "Now") {
      const qr = cardselect["contentHTML"];
      emailData = {
        sender: { name: myCookieValueName, email: email },
        to: [{ email: emailTo, name: nameTo }],
        subject: sub,
        htmlContent: "Xin chào " + nameTo + cardselect["contentHTML"],
      };
    } else {
      const valueThoigian = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(selectedDateTime);
      const convertDateTime = moment(valueThoigian).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      emailData = {
        sender: { name: "TM Software", email: email },
        to: [{ email: emailTo, name: nameTo }],
        subject: sub,
        scheduledAt: convertDateTime,
        htmlContent: "Xin chào " + nameTo + cardselect["contentHTML"],
      };
    }

    // Send the API request
    fetch("https://api.brevo.com/v3/smtp/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "api-key": apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error:", error);
      });
  };

  const getEmailTemplate = () => {
    const myCookieValue = getCookieCode("TMUserCodeCookie");
    const dataBody = { idCustomer: myCookieValue };
    fetch(
      "https://hoangthongtelecom.com/API/SendZalo/v1/Email/getEmailTemplate.php",
      {
        method: "POST",
        body: JSON.stringify(dataBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const data = result;
        // Handle the response from the PHP file
        setvalueTemplate(data);
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        console.error(error);
      });
  };

  // const handleChange = (content, delta, source, editor) => {
  //   console.log(content); // delta 사용시
  // };
  const handleButtonClick = async () => {
    setopenLoading(true);
    const selectedRowData = selectedRows.map((id) =>
      props.data.find((row) => row.id === id)
    );
    const promises = selectedRowData.map((item) => sendEmailMarketing(item));
    await Promise.all(promises);
    setopenLoading(false);
    setOpen(true);
    setvalueThongbao("Hoàn thành")
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
    console.log(selectionModel);
  };
  const handleSaveSender = () => {
    const name = document.getElementById("outlined-name-sender").value;
    const email = document.getElementById("outlined-email-sender").value;
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key": valueKeyAPI,
      },
      body: JSON.stringify({ name: name, email: email }),
    };

    fetch("https://api.brevo.com/v3/senders", options)
      .then((response) => response.json())
      .then((response) => {
        // setvalueThongbao("Lưu thành công");
        const id = response["id"];
        setvalueIDSender(id);
        setOpenSender(false);
        // setOpen(true);
        setopenActiveEmail(true);
      })
      .catch((err) => console.error(err));
  };

  const handleCloseActiveEmail = () => {
    setopenActiveEmail(false);
  };

  const activeEmailSender = () => {
    const _valueIDSender = valueIDSender;
    const otpEmail = document.getElementById("outlined-otp-sender").value;
    const convertedNumber = parseInt(otpEmail);
    const options = {
      method: "PUT",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        "api-key": valueKeyAPI,
      },
      body: JSON.stringify({ otp: otpEmail }),
    };

    const url =
      "https://api.brevo.com/v3/senders/" + _valueIDSender + "/validate";

    fetch(url)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  return (
    <div className="main-dashboard-email">
      <div style={{ marginBottom: 8, marginRight: 3, display: "flex" }}>
        <div style={{ flexGrow: 1 }}>Gửi email marketing</div>
        <Button
          variant="contained"
          style={{ width: "100px", height: "33px" }}
          onClick={handleButtonClick}
        >
          Send
        </Button>

        {/* <Button variant="contained" onClick={sendEmailMarketing}>
          Send Email
        </Button> */}
      </div>
      <Grid container spacing={0.5}>
        <Grid item xs={12} md={6}>
          <DataGrid
            sx={{ height: "82vh", backgroundColor: "white", width: "100%" }}
            rows={props.data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20]}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionModelChange}
            onSelectionModelChange={handleSelectionModelChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="container-send">
            <div style={{ marginBottom: 10, display: "flex" }}>
              <div style={{ width: "110px" }}>Email gửi</div>
              <TextField
                fullWidth
                size="small"
                id="outlined-email"
                variant="outlined"
                value={valueEmail}
                InputProps={{
                  readOnly: true,
                }}
              />
              {/* <IconButton aria-label="delete" onClick={handleOpenSender}>
                <AddCircleOutlineOutlinedIcon />
              </IconButton> */}
            </div>
            <div style={{ marginBottom: 10, display: "flex" }}>
              <div style={{ width: "110px" }}>Subject</div>
              <TextField
                fullWidth
                size="small"
                id="outlined-subject"
                variant="outlined"
              />
            </div>
            <div style={{ marginBottom: 5 }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{ flexGrow: 1, height: "30px", paddingTop: "10px" }}
                >
                  Thiết lập Gửi
                </div>
                <Radio
                  checked={selectedValue === "Now"}
                  onChange={handleChangeSendEmail}
                  value="Now"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "Now" }}
                />
                <div
                  style={{
                    height: "30px",
                    paddingTop: "10px",
                    marginRight: "10px",
                  }}
                >
                  Gửi ngay
                </div>
                <Radio
                  checked={selectedValue === "Late"}
                  onChange={handleChangeSendEmail}
                  value="Late"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "Late" }}
                />
                <div style={{ height: "30px", paddingTop: "10px" }}>
                  Gửi sau
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "120px", marginTop: "15px" }}>
                  Thời gian gửi
                </div>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"en-gb"}
                >
                  <DemoContainer components={["DateTimePicker"]}>
                    <DateTimePicker
                      value={selectedDateTime}
                      onChange={(newValue) => setSelectedDateTime(newValue)}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div>
              <div style={{ display: "flex", marginBottom: "5px" }}>
                <div style={{ flexGrow: 1 }}>Template</div>
                <Button
                  size="small"
                  variant="text"
                  onClick={openDialogTemplate}
                >
                  Mẫu có sẵn
                </Button>
                <div style={{ width: "15px" }}></div>
                <Button
                  to="/Template"
                  size="small"
                  variant="text"
                  onClick={openNewTab}
                >
                  Tạo mới
                </Button>
              </div>
              {cardselect === "" ? (
                <div></div>
              ) : (
                <Card
                  key={cardselect["idTemplate"]}
                  sx={{
                    maxWidth: 245,
                    cursor: "pointer",
                    "&:hover": {
                      boxShadow: "0px 8px 6px -6px #999",
                      backgroundColor: "grey",
                    },
                    "&hove..hover-button": {
                      display: "block",
                    },
                  }}
                >
                  <CardMedia
                    sx={{ height: 250, margin: "5px" }}
                    image={cardselect["imageTemplate"]}
                    title="green iguana"
                  />
                </Card>
              )}
            </div>
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Thông báo</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {valueThongbao}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openTemplate}
        onClose={closeDialogTemplate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Template</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} className="container-template">
            {valueTemplate === "" ? (
              <div></div>
            ) : (
              valueTemplate.map((item, index) => (
                <Grid item xs={4}>
                  <div className="div-card-template" key={index}>
                    <Card
                      key={item["idTemplate"]}
                      sx={{
                        maxWidth: 345,
                        overflow: "hidden",
                        cursor: "pointer",
                        "&:hover": {
                          boxShadow: "0px 8px 6px -6px #999",
                          backgroundColor: "grey",
                        },
                        "&hove..hover-button": {
                          display: "block",
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ height: 250, margin: "5px", objectFit: "cover" }}
                        image={item["imageTemplate"]}
                        title="green iguana"
                        alt="Description"
                      />
                    </Card>
                    <button
                      className="hidden-button"
                      onClick={handleButtonTemplateClick}
                      id={item["idTemplate"]}
                    >
                      Chọn
                    </button>
                    <button
                      className="hidden-button-edit"
                      onClick={editTemplate}
                      id={item["idTemplate"]}
                    >
                      Edit
                    </button>
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSender}
        onClose={handleCloseSender}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Thêm email gửi</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>Name</div>
            <div style={{ marginBottom: "10px" }}>
              <TextField
                fullWidth
                sx={{ marginRight: "8px", minWidth: "280px" }}
                size="small"
                id="outlined-name-sender"
                variant="outlined"
              />
            </div>
            <div>Email</div>
            <div>
              <TextField
                fullWidth
                sx={{ marginRight: "8px" }}
                size="small"
                id="outlined-email-sender"
                variant="outlined"
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveSender} autoFocus>
            Save
          </Button>
          <Button onClick={handleCloseSender} autoFocus>
            Cancle
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openActiveEmail}
        onClose={handleCloseActiveEmail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Active Email</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>Code</div>
            <div>
              <TextField
                fullWidth
                sx={{ marginRight: "8px" }}
                size="small"
                id="outlined-otp-sender"
                variant="outlined"
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={activeEmailSender} autoFocus>
            Active
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLoading}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Vui lòng đợi"}</DialogTitle>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default DashboardEmail;
